<script setup lang="ts">
  const pageInfoStore = usePageInfoStore();

  const qualityInfoEl = ref();

  const navigationStore = useNavigationStore();

  useIntersectionObserver(
    qualityInfoEl,
    ([{ isIntersecting }]) => {
      const tab = navigationStore.navigationTabs.find((t) => t.link === 'quality-info');
      if (tab) {
        tab.isActive = isIntersecting;
      }
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div ref="qualityInfoEl" class="quality-info-container main-container">
    <div id="quality-info" data-nav="Контроль качества" class="quality-info-title scroll-normalize">
      Многоуровневая система<br>контроля качества лечения
    </div>
    <div class="quality-info">
      <img src="~/assets/img/doctors/quality-info.webp" class="quality-info-img"></img>
      <div class="quality-info-items">
        <div class="quality-info-item">
          <span class="quality-info-item-title">Главный врач</span>
          <span class="quality-info-item-text">Руководит процессом лечения и контролирует его эффективность на каждом этапе</span>
        </div>
        <div class="quality-info-item">
          <span class="quality-info-item-title">Контрольно-экспертная группа</span>
          <span class="quality-info-item-text">Проводит независимую проверку точности диагностики и качества лечения</span>
        </div>
        <div class="quality-info-item">
          <span class="quality-info-item-title">Руководитель клиники</span>
          <span class="quality-info-item-text">Обеспечивает высокий стандарт обслуживания и следит за качеством всех процессов</span>
        </div>
      </div>
    </div>
    <div class="quality-buttons">
      <NuxtLink
        target="_blank"
        rel="nofollow"
        external
        :href="pageInfoStore.getWhatsAppPhone"
        class="quality-button"
      >
        <IconDoctorItemReviews filled class="quality-button-icon" />
        <div class="quality-button-content">
          <span class="quality-button-title">Написать в поддержку</span>
          <span class="quality-button-text">Если у вас возникли вопросы, свяжитесь с нами</span>
        </div>
        <IconChevronDown class="quality-button-chevron" />
      </NuxtLink>
      <NuxtLink
        target="_blank"
        rel="nofollow"
        external
        :href="pageInfoStore.whatsAppShon"
        class="quality-button"
      >
        <IconDoctorQualityInfo filled class="quality-button-icon" />
        <div class="quality-button-content">
          <span class="quality-button-title">Написать в службу заботы</span>
          <span class="quality-button-text">Если вас не устраивает лечение, напишите нам</span>
        </div>
        <IconChevronDown class="quality-button-chevron" />
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.quality-info-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 0px;
  @media (max-width: 1023px) {
    padding: 30px 20px;
    gap: 30px;
  }
}
.scroll-normalize {
  scroll-margin-top: 160px;
  @media (max-width: 1023px) {
    scroll-margin-top: 120px;
  }
}
.quality-info-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #262633;
  text-align: center;
  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.quality-info {
  display: flex;
  gap: 20px;
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }
}
.quality-info-img {
  display: flex;
  max-width: 676px;
  min-width: 320px;
  object-fit: cover;
  height: 430px;
  border-radius: 30px;
  @media (max-width: 1023px) {
    min-width: 100%;
    height: auto;
  }
}
.quality-info-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.quality-info-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border-radius: 30px;
  background-color: #F6F7FA;
}
.quality-info-item-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
}
.quality-info-item-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.quality-buttons {
  display: flex;
  gap: 20px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 0px;
  }
}
.quality-button {
  display: flex;
  align-items: start;
  justify-content: start;
  padding: 24px;
  border-radius: 30px;
  gap: 16px;
  background-color: #F6F7FA;
  max-width: 575px;
  flex-grow: 1;
  text-decoration: none;
  @media (max-width: 1023px) {
    max-width: 100%;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
}
a:nth-child(odd of .quality-button) {
  @media (max-width: 1023px) {
    border-bottom: 1px #E4E7EF solid;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
}
.quality-button-icon {
  font-size: 36px;
  margin: 0;
  min-width: 36px;
}
.quality-button-content {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  @media (max-width: 1023px) {
    gap: 4px;
  }
}
.quality-button-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
}
.quality-button-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: start;
  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
}
.quality-button-chevron {
  font-size: 24px;
  min-width: 24px;
  margin: 0;
  color: #878FA2;
  transform: rotate(-90deg);
}
</style>
